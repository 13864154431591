import React from 'react'
import { navigate } from '@reach/router'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import InstagramIcon from '../assets/instagram.svg'
import TwitterIcon from '../assets/twitter.svg'
import NewsletterForm from '../forms/NewsletterForm'

const StyledSkewTop = styled.div`
  height: 30px;
  margin-top: -24px;
  -webkit-transform: skewY(0.5deg);
  -moz-transform: skewY(0.5deg);
  -ms-transform: skewY(0.5deg);
  -o-transform: skewY(0.5deg);
  transform: skewY(0.5deg);

  @media (max-width:767px) {
    margin-top: -26px;
  }
`

const StyledP = styled.p`
  font-size: 8px;
`

const menuItem = 'text-sm text-grolens-white-200 hover:text-grolens-green-400 font-body mx-4 sm:mb-4 md:mb-4 sm:mx-0 md:mx-0'

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    query FooterNavQuery {
      imageOne: file(relativePath: { eq: "logo/grolens-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 97) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "logo/cga_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 91) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageArrow: file(relativePath: { eq: "sections/arrow-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 21) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageOne = data.imageOne.childImageSharp.fluid
  const imageTwo = data.imageTwo.childImageSharp.fluid
  const imageArrow = data.imageArrow.childImageSharp.fluid

  return (
    <footer className='relative bg-grolens-green-900 px-4 py-16 sm:px-10 md:px-10'>
      <StyledSkewTop className='absolute top-0 left-0 right-0 w-full bg-grolens-green-500'></StyledSkewTop>

      <div className='flex sm:flex-col-reverse md:flex-col-reverse sm:w-full md:w-full lg:w-10/12 xl:w-8/12 mx-auto pb-8 mb-4 border-b border-grolens-green-800'>
        <div className='sm:w-full md:w-full w-1/2 flex items-end sm:justify-between'>
          <Img fluid={imageOne} alt='GroLens logo' className='w-24 mr-10' />
          <Img fluid={imageTwo} alt='CGA' className='w-16 sm:mr-6 md:mr-6' />
        </div>

        <div className='sm:w-full md:w-full w-1/2 flex flex-col items-end sm:mb-6 md:mb-6'>
          <div className='sm:w-full md:w-full w-8/12'>
            <h2 className='font-display text-grolens-white-200 text-lg font-medium mb-4'>Sign up to receive the latest GroLens news<br className='sm:hidden' /> & insights in your inbox <Img fluid={imageArrow} alt='' className='inline-block w-5 ml-2' /></h2>

            <NewsletterForm />

            <StyledP className='font-body text-grolens-white-200'>I agree to receive email communication from GroLens related to this offer, new upcoming events and content. You may edit your communications preferences and/or opt-out at any time.</StyledP>
          </div>
        </div>
      </div>

      <div className='flex sm:flex-col-reverse md:flex-col-reverse sm:w-full md:w-full lg:w-10/12 xl:w-8/12 items-center mx-auto sm:mt-8 md:mt-8'>
        <div className='flex sm:flex-col sm:w-full md:w-full w-4/12'>
          <p className='font-body text-sm text-grolens-white-200'>Copyright © {new Date().getFullYear()} GroLens</p> 
          <Link to='/terms' className='text-sm text-grolens-white-200 hover:text-grolens-green-400 font-body ml-8 sm:mt-6 md:mt-6 sm:mx-0 md:mx-0'>Terms & Conditions</Link>
        </div>

        <div className="sm:w-full md:w-full w-8/12 flex sm:flex-col md:flex-col items-center sm:items-start md:items-start justify-end">
          <div className='sm:flex sm:flex-col md:flex md:flex-col sm:mb-4 md:mb-4'>
            <a href='/#why-grolens' onClick={() => navigate('/#why-grolens')} className={menuItem}>Why GroLens?</a>
            <a href='/#testimonials' onClick={() => navigate('/#testimonials')} className={menuItem}>Testimonials</a>
            <a href='/#features' onClick={() => navigate('/#features')} className={menuItem}>Features</a>
            <a href='/#team' onClick={() => navigate('/#team')} className={menuItem}>Team</a>
            <Link to='/blog' className={menuItem}>Blog</Link>

            <a href='https://app.grolens.com/signIn'>
              <button className="text-grolens-white-200 hover:text-grolens-green-400 font-medium uppercase text-sm font-body mx-4 sm:mx-0 md:mx-0">
                Log In &#62;
              </button>
            </a>
          </div>

          <div className='flex sm:my-6 md:my-6'>
            <a href='https://www.instagram.com/gro_lens' target='_blank' rel='noopener noreferrer'><div className='w-5 mx-2 sm:ml-0 md:ml-0 sm:mr-6 md:mr-6 transform hover:scale-105'><InstagramIcon /></div><span className='hidden'>Instagram Icon</span></a>

            <a href='https://twitter.com/gro_lens' target='_blank' rel='noopener noreferrer'><div className='w-5 ml-2 sm:ml-0 md:ml-0 transform hover:scale-105'><TwitterIcon /></div><span className='hidden'>Twitter Icon</span></a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterNav
import React from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import Logo from "../common/Logo"
import styled from "styled-components"

const StyledLink = styled.a`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: #1a3840;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`

const StyledLink2 = styled(Link)`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: #1a3840;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`

const menuItem = "text-grolens-green-800 font-body mx-5 cursor-pointer"

const HeaderNav = ({ isHome }) => (
  <nav
    className={
      isHome
        ? "absolute top-0 left-auto right-auto sm:w-full md:w-11/12 lg:w-10/12 xl:w-8/12 flex items-center justify-between flex-wrap bg-transparent py-5 sm:px-10 sm:pt-8 z-50"
        : "mx-auto sm:w-full md:w-full lg:w-10/12 xl:w-8/12 flex items-center justify-between flex-wrap bg-white py-5 z-50"
    }
  >
    <div className="w-16 sm:w-12">
      <Link to="/">
        <Logo />
      </Link>
    </div>

    <div className="sm:hidden md:hidden flex items-center w-auto">
      <div>
        <StyledLink2 to="/#why-grolens" className={menuItem}>
          Why GroLens?
        </StyledLink2>
        <StyledLink2 to="/#testimonials" className={menuItem}>
          Testimonials
        </StyledLink2>
        <StyledLink2 to="/#features" className={menuItem}>
          Features
        </StyledLink2>
        <StyledLink2 to="/#team" className={menuItem}>
        Team
        </StyledLink2>
        <StyledLink2 to="/blog" className={menuItem}>
          Blog
        </StyledLink2>

        <Link to="/request-demo">
          <button className="border border-grolens-green-800 hover:border-grolens-green-600 text-grolens-green-800 hover:text-grolens-green-600 font-bold uppercase text-md font-body py-2 px-6 ml-5">
            Get Price
          </button>
        </Link>

        <a href="https://app.grolens.com/signIn">
          <button className="text-grolens-green-900 hover:text-grolens-green-600 font-bold uppercase text-md font-body py-2 px-6 ml-2">
            Log In
          </button>
        </a>
      </div>
    </div>
  </nav>
)

export default HeaderNav

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      imageLogo: file(relativePath: { eq: "logo/grolens-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 97) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageLogo = data.imageLogo.childImageSharp.fluid

  return (
    <Img fluid={imageLogo} alt='GroLens logo' />
  )
}

export default Logo